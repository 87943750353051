/* eslint-disable no-param-reassign */
import { asyncRouterMap, constantRouterMap } from '@/router';
/**
 * 判断是否与当前用户权限匹配
 */

function hasPermission(resources, route) {
  if (route.meta && route.meta.permission && route.meta.permission.length) {
    // eslint-disable-next-line max-len
    return route.meta.permission.some(function (item) {
      return !!resources.find(function (resource) {
        return resource === item;
      });
    });
  }

  return true;
}
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param resources
 */
// eslint-disable-next-line


function filterAsyncRouter(asyncRouterMap, resources) {
  var accessedRouters = asyncRouterMap.filter(function (route) {
    if (hasPermission(resources, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, resources);
      }

      return true;
    }

    return false;
  });
  return accessedRouters;
}

var permission = {
  state: {
    addRouters: [],
    routers: constantRouterMap
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    /**
     * 根据权限生成路由
     */
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var accessedRouters = filterAsyncRouter(asyncRouterMap, data.permissions);
        commit('SET_ROUTERS', accessedRouters);
        resolve(accessedRouters);
      });
    }
  }
};
export default permission;