import "core-js/modules/es6.array.copy-within.js";
import "core-js/modules/es6.array.fill.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/es6.array.of.js";
import "core-js/modules/es6.array.sort.js";
import "core-js/modules/es6.array.species.js";
import "core-js/modules/es6.date.to-primitive.js";
import "core-js/modules/es6.function.has-instance.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.math.acosh.js";
import "core-js/modules/es6.math.asinh.js";
import "core-js/modules/es6.math.atanh.js";
import "core-js/modules/es6.math.cbrt.js";
import "core-js/modules/es6.math.clz32.js";
import "core-js/modules/es6.math.cosh.js";
import "core-js/modules/es6.math.expm1.js";
import "core-js/modules/es6.math.fround.js";
import "core-js/modules/es6.math.hypot.js";
import "core-js/modules/es6.math.imul.js";
import "core-js/modules/es6.math.log1p.js";
import "core-js/modules/es6.math.log10.js";
import "core-js/modules/es6.math.log2.js";
import "core-js/modules/es6.math.sign.js";
import "core-js/modules/es6.math.sinh.js";
import "core-js/modules/es6.math.tanh.js";
import "core-js/modules/es6.math.trunc.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.number.epsilon.js";
import "core-js/modules/es6.number.is-finite.js";
import "core-js/modules/es6.number.is-integer.js";
import "core-js/modules/es6.number.is-nan.js";
import "core-js/modules/es6.number.is-safe-integer.js";
import "core-js/modules/es6.number.max-safe-integer.js";
import "core-js/modules/es6.number.min-safe-integer.js";
import "core-js/modules/es6.number.parse-float.js";
import "core-js/modules/es6.number.parse-int.js";
import "core-js/modules/es6.object.assign.js";
import "core-js/modules/es7.object.define-getter.js";
import "core-js/modules/es7.object.define-setter.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.object.freeze.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.object.get-own-property-names.js";
import "core-js/modules/es6.object.get-prototype-of.js";
import "core-js/modules/es7.object.lookup-getter.js";
import "core-js/modules/es7.object.lookup-setter.js";
import "core-js/modules/es6.object.prevent-extensions.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.object.is.js";
import "core-js/modules/es6.object.is-frozen.js";
import "core-js/modules/es6.object.is-sealed.js";
import "core-js/modules/es6.object.is-extensible.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.seal.js";
import "core-js/modules/es6.object.set-prototype-of.js";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.reflect.apply.js";
import "core-js/modules/es6.reflect.construct.js";
import "core-js/modules/es6.reflect.define-property.js";
import "core-js/modules/es6.reflect.delete-property.js";
import "core-js/modules/es6.reflect.get.js";
import "core-js/modules/es6.reflect.get-own-property-descriptor.js";
import "core-js/modules/es6.reflect.get-prototype-of.js";
import "core-js/modules/es6.reflect.has.js";
import "core-js/modules/es6.reflect.is-extensible.js";
import "core-js/modules/es6.reflect.own-keys.js";
import "core-js/modules/es6.reflect.prevent-extensions.js";
import "core-js/modules/es6.reflect.set.js";
import "core-js/modules/es6.reflect.set-prototype-of.js";
import "core-js/modules/es6.regexp.constructor.js";
import "core-js/modules/es6.regexp.flags.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es7.symbol.async-iterator.js";
import "core-js/modules/es6.string.anchor.js";
import "core-js/modules/es6.string.big.js";
import "core-js/modules/es6.string.blink.js";
import "core-js/modules/es6.string.bold.js";
import "core-js/modules/es6.string.code-point-at.js";
import "core-js/modules/es6.string.ends-with.js";
import "core-js/modules/es6.string.fixed.js";
import "core-js/modules/es6.string.fontcolor.js";
import "core-js/modules/es6.string.fontsize.js";
import "core-js/modules/es6.string.from-code-point.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es6.string.italics.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.string.link.js";
import "core-js/modules/es7.string.pad-start.js";
import "core-js/modules/es7.string.pad-end.js";
import "core-js/modules/es6.string.raw.js";
import "core-js/modules/es6.string.repeat.js";
import "core-js/modules/es6.string.small.js";
import "core-js/modules/es6.string.starts-with.js";
import "core-js/modules/es6.string.strike.js";
import "core-js/modules/es6.string.sub.js";
import "core-js/modules/es6.string.sup.js";
import "core-js/modules/es7.string.trim-left.js";
import "core-js/modules/es7.string.trim-right.js";
import "core-js/modules/es6.typed.array-buffer.js";
import "core-js/modules/es6.typed.int8-array.js";
import "core-js/modules/es6.typed.uint8-array.js";
import "core-js/modules/es6.typed.uint8-clamped-array.js";
import "core-js/modules/es6.typed.int16-array.js";
import "core-js/modules/es6.typed.uint16-array.js";
import "core-js/modules/es6.typed.int32-array.js";
import "core-js/modules/es6.typed.uint32-array.js";
import "core-js/modules/es6.typed.float32-array.js";
import "core-js/modules/es6.typed.float64-array.js";
import "core-js/modules/es6.weak-map.js";
import "core-js/modules/es6.weak-set.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/web.immediate.js";
import "core-js/modules/web.dom.iterable.js";
import "regenerator-runtime/runtime.js";
import Vue from 'vue';
import 'normalize.css';
import Element from 'element-ui'; // import Quill from 'quill';

import 'echarts';
import VueECharts from 'vue-echarts';
import VueCompositionAPI from '@vue/composition-api';
import './styles/element-variables.scss'; // import Cookies from 'js-cookie';

import router from '@/router';
import App from '@/views';
import store from '@/store';
import filters from '@/filters';
import config from '@/config';
import '@/permission';
import '@/styles/index.scss'; // window.Quill = Quill;

Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.use(Element, {
  size: 'small' // set element-ui default size
  // size: Cookies.get('size') || 'medium', // set element-ui default size

});
Vue.use(VueCompositionAPI);
Vue.component('v-charts', VueECharts);
/**
 * 注册filters
 */

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
}); // eslint-disable-next-line

window.__g_store__ = store; // eslint-disable-next-line

window.__g_router__ = router; // eslint-disable-next-line

window.__g_config__ = config;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');