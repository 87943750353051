export default {
  enterpriseTypeList: [{
    label: '控排',
    value: 1
  }, {
    label: '非控排',
    value: 2
  }, {
    label: '电子',
    value: 3
  }],
  // ('1', '排控'),('2', '非排控'),('3', '电子')
  reduceProjectType: [{
    label: '垃圾',
    value: 1
  }, {
    label: '生物质',
    value: 2
  }, {
    label: '光伏',
    value: 3
  }, {
    label: '风电',
    value: 4
  }],
  industryEnum: [{
    label: '电力',
    value: '电力'
  }, {
    label: '钢铁',
    value: '钢铁'
  }, {
    label: '石化',
    value: '石化'
  }, {
    label: '化工',
    value: '化工'
  }, {
    label: '建材',
    value: '建材'
  }, {
    label: '有色',
    value: '有色'
  }, {
    label: '制造',
    value: '制造'
  }, {
    label: '航空',
    value: '航空'
  }]
};