import Layout from '@/layout';
export default {
  path: '/operations',
  component: Layout,
  alwaysShow: true,
  meta: {
    title: '运维管理',
    icon: 's-operation',
    permission: ['operationsManage']
  },
  children: [{
    path: 'employee',
    component: function component() {
      return import('@/views/operations/employee');
    },
    name: 'employeeManage',
    meta: {
      title: '员工管理',
      // icon: '',
      permission: ['OperationsManage/EmployeeManage']
    }
  }, {
    path: 'inspection-item',
    component: function component() {
      return import('@/views/operations/inspectionItem');
    },
    name: 'inspectionItemManage',
    meta: {
      title: '巡检项管理',
      // icon: '',
      permission: ['OperationsManage/InspectionItemManage']
    }
  }, {
    path: 'inspection',
    component: function component() {
      return import('@/views/operations/inspection');
    },
    name: 'inspectionManage',
    meta: {
      title: '巡检对象管理',
      // icon: '',
      permission: ['OperationsManage/InspectionManage']
    }
  }, {
    path: 'inspection-plan',
    component: function component() {
      return import('@/views/operations/inspectionPlan');
    },
    name: 'inspectionPlanManage',
    meta: {
      title: '巡检计划管理',
      // icon: '',
      permission: ['OperationsManage/InspectionPlanManage']
    }
  }, {
    path: 'inspection-record',
    component: function component() {
      return import('@/views/operations/inspectionRecord');
    },
    name: 'inspectionRecordManage',
    meta: {
      title: '巡检记录管理',
      // icon: '',
      permission: ['OperationsManage/InspectionRecordManage']
    }
  }, {
    path: 'work-order',
    component: function component() {
      return import('@/views/operations/workOrder');
    },
    name: 'workOrderManage',
    meta: {
      title: '工单管理',
      // icon: '',
      permission: ['OperationsManage/WorkOrderManage']
    }
  }]
};