import Layout from '@/layout';
import LayoutPlain from '@/layout/plain';
export default {
  path: '/carbon',
  component: Layout,
  meta: {
    title: '碳管理',
    icon: 'aim'
  },
  children: [{
    path: '/carbon/park',
    component: LayoutPlain,
    meta: {
      title: '园区管理',
      icon: 'office-building',
      permission: ['UserCarbonManage/ParkManage']
    },
    children: [{
      path: '/park/list',
      component: function component() {
        return import('@/views/parkManage');
      },
      name: 'parkManage',
      meta: {
        title: '园区管理' // icon: 'truck',

      }
    }, {
      path: '/park/create',
      component: function component() {
        return import('@/views/parkManage/edit');
      },
      name: 'createPark',
      hidden: true,
      meta: {
        title: '新增园区',
        type: 'create' //     // icon: 'truck',
        // permission: ['DeviceManage/SpecsManage'],

      }
    }, {
      path: '/park/edit/:id',
      component: function component() {
        return import('@/views/parkManage/edit');
      },
      name: 'editPark',
      hidden: true,
      meta: {
        title: '编辑园区',
        type: 'update' //     // icon: 'truck',
        // permission: ['DeviceManage/SpecsManage'],

      }
    }]
  }, {
    path: '/carbon/enterprise',
    component: LayoutPlain,
    // alwaysShow: true,
    meta: {
      title: '企业管理',
      icon: 'suitcase',
      permission: ['UserCarbonManage/EnterpriseManage']
    },
    children: [{
      path: '/enterprise/list',
      component: function component() {
        return import('@/views/enterpriseManage');
      },
      name: 'enterpriseManage',
      meta: {
        title: '企业管理' // icon: 'truck',
        // permission: ['DeviceManage/ManufacturersManage'],

      }
    }, {
      path: '/enterprise/create',
      component: function component() {
        return import('@/views/enterpriseManage/edit');
      },
      name: 'createEnterprise',
      hidden: true,
      meta: {
        title: '新增企业',
        type: 'create' //     // icon: 'truck',
        // permission: ['DeviceManage/SpecsManage'],

      }
    }, {
      path: '/enterprise/edit/:id',
      component: function component() {
        return import('@/views/enterpriseManage/edit');
      },
      name: 'editEnterprise',
      hidden: true,
      meta: {
        title: '编辑企业',
        type: 'update' //     // icon: 'truck',
        // permission: ['DeviceManage/SpecsManage'],

      }
    } // {
    //   path: '/device/spec',
    //   component: () => import('@/views/device/specManage'),
    //   name: 'specMagage',
    //   meta: {
    //     title: '型号管理',
    //     //     // icon: 'truck',
    //     permission: ['DeviceManage/SpecsManage'],
    //   },
    // }
    ]
  }, {
    path: '/carbon/manage',
    component: LayoutPlain,
    meta: {
      title: '碳价管理',
      icon: 'office-building',
      permission: ['UserCarbonManage/CarbonPrice']
    },
    children: [{
      path: '/carbon/price',
      component: function component() {
        return import('@/views/carbon/carbonPrice');
      },
      name: 'carbonPrice',
      meta: {
        title: '碳价管理'
      }
    }]
  }, {
    path: '/carbon/industry',
    component: LayoutPlain,
    meta: {
      title: '行业管理',
      icon: 'office-building',
      permission: ['UserCarbonManage/Industry']
    },
    children: [{
      path: '/carbon/industry/list',
      component: function component() {
        return import('@/views/carbon/Industry');
      },
      name: 'carbonIndustry',
      meta: {
        title: '行业管理'
      }
    }]
  }]
};