//
//
//
//
//
//
export default {
  name: 'App',
  data: function data() {
    return {// init: false,
    };
  }
};