import dayjs from 'dayjs';

function formatDate(value, format) {
  if (value) {
    return dayjs(value).format(format);
  }

  return '';
}

export default formatDate;