//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  created: function created() {
    this.getBreadcrumb();
  },
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];

      if (first && first.name !== 'dashboard') {
        matched = [{
          path: '/dashboard',
          meta: {
            title: '首页'
          }
        }].concat(matched);
      }

      this.levelList = matched;
    }
  }
};