import Layout from '@/layout';
export default {
  path: '/account/user',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '用户权限',
    icon: 'user'
  },
  children: [{
    path: '/account/user/list',
    component: function component() {
      return import('@/views/userManage');
    },
    name: 'userManage',
    meta: {
      title: '用户管理',
      permission: ['UserPermission/UserManage']
    }
  }, {
    path: '/account/role/list',
    component: function component() {
      return import('@/views/roleManage');
    },
    name: 'roleManage',
    meta: {
      title: '角色管理',
      permission: ['UserPermission/RoleManage']
    }
  }, {
    path: '/account/permission/list',
    component: function component() {
      return import('@/views/permissionManage');
    },
    name: 'permissionManage',
    meta: {
      title: '前台菜单权限',
      permission: ['UserPermission/MenuPermission']
    }
  }, {
    path: '/account/adminPermission/list',
    component: function component() {
      return import('@/views/adminPermissionManage');
    },
    name: 'permissionManage',
    meta: {
      title: '后台菜单权限',
      permission: ['UserPermission/MenuPermission']
    }
  }]
};