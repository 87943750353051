//
//
//
//
export default {
  name: 'Plain',
  computed: {// cachedViews() {
    //   return this.$store.state.tagsView.cachedViews;
    // },
  }
};