import Layout from '@/layout';
export default {
  path: '/device',
  component: Layout,
  alwaysShow: true,
  meta: {
    title: '设备管理',
    icon: 'cpu'
  },
  children: [{
    path: '/device/manufacturer',
    component: function component() {
      return import('@/views/device/manufacturerManage');
    },
    name: 'manufacturerManage',
    meta: {
      title: '厂商管理',
      // icon: 'truck',
      permission: ['DeviceManage/ManufacturersManage']
    }
  }, {
    path: '/device/spec',
    component: function component() {
      return import('@/views/device/specManage');
    },
    name: 'specMagage',
    meta: {
      title: '型号管理',
      //     // icon: 'truck',
      permission: ['DeviceManage/SpecsManage']
    }
  }, {
    path: '/device/unit',
    component: function component() {
      return import('@/views/device/unitManage');
    },
    name: 'unitMagage',
    meta: {
      title: '单位管理',
      //     // icon: 'truck',
      permission: ['DeviceManage/UnitsManage']
    }
  }, {
    path: '/device/template',
    component: function component() {
      return import('@/views/device/templateManage');
    },
    name: 'templateManage',
    meta: {
      title: '设备模板',
      permission: ['DeviceManage/DeviceTemplate']
    }
  }, {
    path: '/device/collector',
    component: function component() {
      return import('@/views/device/collectorManage');
    },
    name: 'collectorManage',
    meta: {
      title: '设备采集器',
      permission: ['DeviceManage/DeviceAdaptor']
    }
  }, {
    path: '/device/list',
    component: function component() {
      return import('@/views/device/deviceManage');
    },
    name: 'deviceManage',
    meta: {
      title: '设备管理',
      permission: ['DeviceManage/DeviceManage']
    }
  }, {
    path: '/device/point',
    component: function component() {
      return import('@/views/device/pointManage');
    },
    name: 'pointManage',
    meta: {
      title: '设备点值表',
      permission: ['DeviceManage/DevicePoint']
    }
  }]
};