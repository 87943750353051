function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import carbon from './carbon';

var staticEnums = _objectSpread({
  // yes or no
  yesOrNo: [{
    value: 0,
    label: '否'
  }, {
    value: 1,
    label: '是'
  }],
  // 启用禁用
  onOrOff: [{
    value: false,
    label: '停用'
  }, {
    value: true,
    label: '启用'
  }],
  // 启用禁用
  stationStatus: [{
    value: 0,
    label: '规划中'
  }, {
    value: 1,
    label: '启用'
  }],
  roleType: [{
    value: 'UserMade',
    label: '自定义'
  }, {
    value: 'System',
    label: '系统内置'
  }],
  // 字段类型
  fieldType: [{
    value: 'point',
    label: '点值'
  }, {
    value: 'switch',
    label: '开关量'
  }],
  valueType: [{
    value: 'FLOAT',
    label: '浮点数'
  }, {
    value: 'INT',
    label: '整数'
  }, {
    value: 'BOOLEAN',
    label: '布尔型'
  }, {
    value: 'STRING',
    label: '字符串'
  }, {
    value: 'ENUM',
    label: '枚举类型'
  }],
  // 开关类型
  actionType: [{
    value: '单点开关',
    label: '单点开关'
  }, // {
  //   value: '双点开关',
  //   label: '双点开关',
  // },
  {
    value: '数值配置',
    label: '数值配置'
  }],
  actionTypeValue: [{
    value: 'button',
    label: '遥调开关'
  }, {
    value: 'select',
    label: '遥调可选项'
  }, {
    value: 'input',
    label: '遥调数值'
  }],
  // method类型
  methodType: [{
    value: '直控',
    label: '直控'
  }, {
    value: '选控',
    label: '选控'
  }],
  // unit: [
  //   {
  //     label: 'V',
  //     value: 'V',
  //   },
  //   {
  //     label: 'A',
  //     value: 'A',
  //   },
  //   {
  //     label: 'KW',
  //     value: 'KW',
  //   },
  //   {
  //     label: '℃',
  //     value: '℃',
  //   },
  //   {
  //     label: 'HZ',
  //     value: 'HZ',
  //   },
  //   {
  //     label: 'KWh',
  //     value: 'KWh',
  //   },
  //   {
  //     label: '%',
  //     value: '%',
  //   },
  //   {
  //     label: 'h',
  //     value: 'h',
  //   },
  // ],
  aggDataType: [{
    value: 'STATIC',
    label: '静态'
  }, {
    value: 'SUM',
    label: '总和'
  }, {
    value: 'AVERAGE',
    label: '平均值'
  }, {
    value: 'FUNC',
    label: '内置'
  }, {
    value: 'THROUGH',
    label: '直通'
  }],
  aggFunc: [{
    value: 'CO2减排',
    label: 'CO2减排'
  }, {
    value: 'SO2减排',
    label: 'SO2减排'
  }, {
    value: '碳粉尘减排',
    label: '碳粉尘减排'
  }, {
    value: '节约标准煤',
    label: '节约标准煤'
  }, {
    value: '当前可充容量',
    label: '当前可充容量'
  }, {
    value: '当前可放容量',
    label: '当前可放容量'
  }],
  strategyType: [{
    value: 1,
    label: '单条执行策略(Single)'
  }, {
    value: 2,
    label: '组合执行策略(Combine)'
  }],
  strategyTargetType: [{
    value: 1,
    label: '源设备(Source)'
  }, {
    value: 2,
    label: '相对关联设备(Related)'
  }, {
    value: 3,
    label: '直接关联设备(Direct)'
  }],
  scheduleActionType: [{
    value: 1,
    label: '充电'
  }, {
    value: 2,
    label: '放电'
  }, {
    value: 3,
    label: '空档'
  }],
  // ， 5：事故  4：异常  3：越限  2：变位  1 通知
  alertLevel: [{
    value: 1,
    label: '通知'
  }, {
    value: 2,
    label: '变位'
  }, {
    value: 3,
    label: '越限'
  }, {
    value: 4,
    label: '异常'
  }, {
    value: 5,
    label: '事故'
  }]
}, carbon);

export default staticEnums;