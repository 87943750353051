/**
 * 全局getter
 */
var getters = {
  name: function name(state) {
    return state.user.name;
  },
  isSuper: function isSuper(state) {
    return state.user.isSuper;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  // routers: state => state.permission.routers,
  permission_routes: function permission_routes(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.routers;
  },
  device: function device(state) {
    return state.app.device;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  }
};
export default getters;