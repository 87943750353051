import mapValues from 'lodash.mapvalues';
import { multiStratify } from '@/common/tree'; // 异步枚举获取配置

var enumsConfig = {
  deviceCollectors: {
    url: '/device/adaptor/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  deviceTemplates: {
    url: '/device/template/list?point_detail=true',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  devices: {
    url: '/device/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  policies: {
    url: '/alert/policy/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  manufacturers: {
    url: '/device/manufacturer/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  stations: {
    url: '/station/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  stationTypes: {
    url: '/station/types',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  deviceTypes: {
    url: '/device/types',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  units: {
    url: '/device/unit/list',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.name,
          original: item
        };
      });
    }
  },
  pointList: {
    url: '/device/point_name/list',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.map(function (item) {
        return {
          label: item.name,
          value: item.uuid,
          original: item
        };
      });
    }
  },
  parkList: {
    url: '/carbon/v1/parks/',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.rows.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  enterpriseList: {
    url: '/carbon/v1/enterprises/',
    init: true,
    method: 'GET',
    params: {
      rows: 999,
      page: 1
    },
    responseIntercepter: function responseIntercepter(data) {
      return data.rows.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          original: item
        };
      });
    }
  },
  areaList: {
    url: '/carbon/area/list',
    init: true,
    method: 'GET',
    responseIntercepter: function responseIntercepter(data) {
      var list = data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          parentId: item.parent_id,
          original: item
        };
      });
      return multiStratify(list, 'value', 'parentId');
    }
  } // reports: {
  //   url: '/reports/list',
  //   init: true,
  //   method: 'GET',
  //   responseIntercepter: data => data.map(item => ({
  //     label: item.name,
  //     value: item.id,
  //     original: item,
  //   })),
  // },

};
var asyncEnums = mapValues(enumsConfig, function () {
  return [];
});
export { asyncEnums as default, enumsConfig };