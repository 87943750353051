import Layout from '@/layout';
export default {
  path: '/home',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '主页管理',
    icon: 's-grid'
  },
  children: [{
    path: '/home/platform',
    component: function component() {
      return import('@/views/homeManage/platformConfigure');
    },
    name: 'platformConfigure',
    meta: {
      title: '平台配置',
      // icon: 's-grid',
      permission: ['HomeManage']
    }
  }, {
    path: '/home/point',
    component: function component() {
      return import('@/views/homeManage/pointConfigure');
    },
    name: 'pointConfigure',
    meta: {
      title: '数值配置',
      // icon: 's-grid',
      permission: ['HomeManage']
    }
  }]
};