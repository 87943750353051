import Layout from '@/layout';
export default {
  path: '/system',
  component: Layout,
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'setting'
  },
  children: [// {
  //   path: '/account/user/list',
  //   component: () => import('@/views/userManage'),
  //   name: 'userManage',
  //   meta: {
  //     title: '用户管理',
  //   },
  // },
  {
    path: '/config/get',
    component: function component() {
      return import('@/views/configManage');
    },
    name: 'configManage',
    meta: {
      title: '参数配置',
      permission: ['SystemManage/ParamConfig']
    }
  }, {
    path: '/audit/list',
    component: function component() {
      return import('@/views/auditManage');
    },
    name: 'auditManage',
    meta: {
      title: '操作日志',
      // permission: ['role/list'],
      permission: ['SystemManage/OperationLog']
    }
  } // {
  //   path: '/roleManagement',
  //   component: () => import('@/views/roleManagement/index'),
  //   name: 'roleManagement',
  //   meta: {
  //     title: '角色管理',
  //     icon: 'config',
  //     // permission: ['role/list'],
  //   },
  // },
  // {
  //   path: '/adminManagement',
  //   component: () => import('@/views/adminManagement/index'),
  //   name: 'adminManagement',
  //   meta: {
  //     title: '管理员设置',
  //     icon: 'config',
  //     // permission: ['admin/list'],
  //   },
  // }
  ]
};