import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import config from '@/config';
import app from './app';
import user from './user';
import permission from './permission';
import tagsView from './tagsView';
import enums from './enums';
import settings from './settings';
import getters from './getters';
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
    key: "vuex_".concat(config.storeVersion),
    paths: ['user.isLogin']
  })],
  modules: {
    app: app,
    user: user,
    permission: permission,
    enums: enums,
    tagsView: tagsView,
    settings: settings
  },
  getters: getters,
  strict: false
});