import Layout from '@/layout';
export default {
  path: '/mapping',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '系统图编辑器' // icon: 's-marketing',

  },
  children: [{
    path: '/editor',
    component: function component() {
      return import('@/views/editor');
    },
    name: 'editor',
    meta: {
      title: '系统图编辑器',
      icon: 'edit',
      permission: ['SystemDiagramEdit']
    }
  }]
};