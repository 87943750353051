var toFixed = function toFixed(num, len) {
  var floatNum = Number.parseFloat(num);

  if (floatNum || floatNum === 0) {
    return floatNum.toFixed(len);
  }

  return '';
};

export default toFixed;