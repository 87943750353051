import Layout from '@/layout';
export default {
  path: '/reports',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '报表管理',
    icon: 's-marketing'
  },
  children: [{
    path: '/reports/opticalCharging',
    component: function component() {
      return import('@/views/reportsManage/opticalCharging');
    },
    name: 'opticalCharging',
    meta: {
      title: '光储充',
      // icon: 's-marketing',
      permission: ['ReportManage']
    }
  }, {
    path: '/reports/energyConsumption',
    component: function component() {
      return import('@/views/reportsManage/energyConsumption');
    },
    name: 'reportsManage',
    meta: {
      title: '能耗',
      // icon: 's-marketing',
      permission: ['ReportManage']
    }
  }]
};