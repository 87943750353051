function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import cloneDeep from 'lodash.clonedeep';

function processTree(list, result, pid) {
  // eslint-disable-next-line no-restricted-syntax
  var _iterator = _createForOfIteratorHelper(list),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;

      // console.log(item);
      if (item.parent__id === pid) {
        var newItem = _objectSpread(_objectSpread({}, item), {}, {
          children: []
        });

        result.push(newItem);
        processTree(list, newItem.children, newItem.id);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

export default function arrayToTree(list, pid) {
  var result = [];
  processTree(list, result, pid);
  return result;
}
/**
 * 将list转换为tree结构
 * @param {*} tree
 * @param {*} level
 */

export function multiStratify(list) {
  var idProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var parentIdProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'parentId';
  var cloneList = cloneDeep(list);
  var tempMap = new Map();
  var rootList = []; // eslint-disable-next-line no-plusplus

  for (var i = 0; i < cloneList.length; i++) {
    var item = cloneList[i];
    tempMap.set(item[idProp], item);

    if (!cloneList[i][parentIdProp]) {
      rootList.push(cloneList[i]);
    }
  } // eslint-disable-next-line no-plusplus


  for (var _i = 0; _i < cloneList.length; _i++) {
    var _item = cloneList[_i];

    if (tempMap.has(_item[parentIdProp])) {
      var parent = tempMap.get(_item[parentIdProp]);

      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(_item);
    }
  }

  return rootList;
}