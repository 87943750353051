import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
import system from './system';
import device from './device';
import station from './station';
import reports from './reports';
import user from './user';
import warning from './warning'; // import policy from './policy';

import strategy from './strategy';
import operations from './operations'; // import schedule from './schedule';

import home from './home';
import editor from './editor';
import carbon from './carbon'; // import park from './park';
// import enterprise from './enterprise';

Vue.use(VueRouter);
export var constantRouterMap = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: '/dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'dashboard',
    meta: {
      title: '首页',
      icon: 's-home',
      affix: true
    }
  }]
}, {
  path: '*',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}];
export default new VueRouter({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
/**
 * 动态路由，根据权限添加
 */

export var asyncRouterMap = [system, user, home, carbon, station, // park,
// enterprise,
device, reports, warning, // policy,
strategy, // schedule,
editor, operations];