import Layout from '@/layout';
export default {
  path: '/station',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '站管理',
    icon: 'school'
  },
  children: [{
    path: '/station/list',
    component: function component() {
      return import('@/views/station/stationManage');
    },
    name: 'stationManage',
    meta: {
      title: '站点管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/areaManage/:id',
    component: function component() {
      return import('@/views/station/areaManage');
    },
    name: 'areaManage',
    hidden: true,
    meta: {
      title: '区域管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/organizationManage/:id',
    component: function component() {
      return import('@/views/station/organizationManage');
    },
    name: 'organizationManage',
    hidden: true,
    meta: {
      title: '组织管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/strategy',
    component: function component() {
      return import('@/views/station/strategyManage');
    },
    name: 'strategyManage',
    hidden: true,
    meta: {
      title: '策略管理',
      icon: 'school',
      permission: ['StationManage']
    }
  }, {
    path: '/station/baseInfoManage/:id',
    component: function component() {
      return import('@/views/station/baseInfoManage');
    },
    name: 'baseInfoManage',
    hidden: true,
    meta: {
      title: '基本信息管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/mainLine',
    component: function component() {
      return import('@/views/station/mainLine');
    },
    name: 'mainLine',
    hidden: true,
    meta: {
      title: '主系统图',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/wiremap',
    component: function component() {
      return import('@/views/station/wireMapManage');
    },
    name: 'wireMapManage',
    hidden: true,
    meta: {
      title: '系统图管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/category',
    component: function component() {
      return import('@/views/station/categoryManage');
    },
    name: 'categoryManage',
    hidden: true,
    meta: {
      title: '设备目录管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/area',
    component: function component() {
      return import('@/views/station/areaManage');
    },
    name: 'areaManage',
    hidden: true,
    meta: {
      title: '区域管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem',
    component: function component() {
      return import('@/views/subSystemManage');
    },
    name: 'areaManage',
    hidden: true,
    meta: {
      title: '子系统管理',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/baseInfo/',
    component: function component() {
      return import('@/views/subSystemManage/components/SystemInfo');
    },
    name: 'subSystemBaseInfo',
    hidden: true,
    meta: {
      title: '子系统基本信息',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/device',
    component: function component() {
      return import('@/views/subSystemManage/components/SystemDevice');
    },
    name: 'subSystemDevice',
    hidden: true,
    meta: {
      title: '子系统设备',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/constraint/',
    component: function component() {
      return import('@/views/subSystemManage/components/SystemConstraint');
    },
    name: 'SystemConstraint',
    hidden: true,
    meta: {
      title: '子系统约束',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/photovoltaic/',
    component: function component() {
      return import('@/views/subSystemManage/photovoltaic');
    },
    name: 'photovoltaic',
    hidden: true,
    meta: {
      title: '光伏子系统',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/energy-storage/',
    component: function component() {
      return import('@/views/subSystemManage/energyStorage');
    },
    name: 'energyStorage',
    hidden: true,
    meta: {
      title: '储能子系统',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/heat-pump/',
    component: function component() {
      return import('@/views/subSystemManage/heatPump');
    },
    name: 'heatPump',
    hidden: true,
    meta: {
      title: '热泵子系统',
      permission: ['StationManage']
    }
  }, {
    path: '/station/:id/subSystem/:subsystemid/micro-grid/',
    component: function component() {
      return import('@/views/subSystemManage/microGrid');
    },
    name: 'microGrid',
    hidden: true,
    meta: {
      title: '微网子系统',
      permission: ['StationManage']
    }
  }]
};