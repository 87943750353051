import Layout from '@/layout';
export default {
  path: '/warning',
  component: Layout,
  // alwaysShow: true,
  meta: {
    title: '告警信息',
    icon: 'cpu'
  },
  children: [{
    path: '/warning/equipmentWarning',
    component: function component() {
      return import('@/views/warningManage/equipmentWarning');
    },
    name: 'equipmentWarning',
    meta: {
      title: '设备告警',
      permission: ['AlertInformation/DeviceAlert']
    }
  }, {
    path: '/warning/equipmentAlarm',
    component: function component() {
      return import('@/views/warningManage/equipmentAlarm');
    },
    name: 'equipmentAlarm',
    meta: {
      title: '设备预警',
      permission: ['AlertInformation/DeviceStatusAlert']
    }
  }, {
    path: '/warning/systemWarning',
    component: function component() {
      return import('@/views/warningManage/systemWarning');
    },
    name: 'systemWarning',
    meta: {
      title: '系统告警',
      permission: ['AlertInformation/SystemAlert']
    }
  }]
};