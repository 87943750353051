import Layout from '@/layout';
export default {
  path: '/strategy',
  component: Layout,
  // alwaysShow: false,
  meta: {
    title: '能量大脑',
    icon: 'cpu'
  },
  children: [{
    path: '/strategy/list',
    component: function component() {
      return import('@/views/aiStrategy');
    },
    name: 'brainManage',
    meta: {
      title: '能量大脑',
      icon: 'cpu',
      permission: ['EneryBrain']
    }
  }, {
    path: '/strategy/factor',
    component: function component() {
      return import('@/views/aiStrategy/factorManage');
    },
    name: 'factorManage',
    hidden: true,
    meta: {
      title: '因子管理',
      icon: 'odometer',
      permission: ['EneryBrain']
    }
  }, // {
  //   path: '/strategy/sub-system',
  //   component: () => import('@/views/aiStrategy/subSystemManage'),
  //   name: 'subSystemManage',
  //   hidden: true,
  //   meta: {
  //     title: '子系统管理',
  //     icon: 'odometer',
  //     // permission: ['role/list'],
  //   },
  // },
  {
    path: '/strategy/strategy',
    component: function component() {
      return import('@/views/aiStrategy/strategyManage');
    },
    name: 'strategyManage',
    hidden: true,
    meta: {
      title: '策略因子',
      icon: 'odometer',
      permission: ['EneryBrain'] // permission: ['role/list'],

    }
  }, {
    path: '/strategy/console',
    component: function component() {
      return import('@/views/aiStrategy/consoleManage');
    },
    name: 'consoleManage',
    hidden: true,
    meta: {
      title: '控制台',
      icon: 'odometer',
      permission: ['EneryBrain'] // permission: ['role/list'],

    }
  }, {
    path: '/strategy/plan',
    component: function component() {
      return import('@/views/aiStrategy/planManage');
    },
    name: 'planManage',
    hidden: true,
    meta: {
      title: '计划管理',
      icon: 'odometer',
      permission: ['EneryBrain'] // permission: ['role/list'],

    }
  }, {
    path: '/strategy/target',
    component: function component() {
      return import('@/views/aiStrategy/targetManage');
    },
    name: 'targetManage',
    hidden: true,
    meta: {
      title: '目标管理',
      icon: 'odometer',
      permission: ['EneryBrain'] // permission: ['role/list'],

    }
  }]
};